
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































.cta-text {
  position: relative;
  transform: translateX(0);
}

.cta-text__content {
  @extend %ff-alt;
  @extend %fw-thin;

  position: relative;
  display: block;
  padding-right: col(1, 10);
  padding-left: col(1, 10);
  text-decoration: none;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
  }

  @include mq($until: m) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $c-gray-light;
    }
  }
}

.cta-text__text {
  em {
    @extend %ff-default;
    @extend %fw-normal;
  }
}

.cta-text__title {
  max-width: 6em;
  margin-bottom: $spacing * 1.5;
  color: $c-mustard;
}

.cta-text__arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: col(3, 8);
  color: $c-mustard;
  transition: transform 0.3s, color 0.2s;

  .icon {
    flex: 1;
    width: 100%;
  }

  .cta-text__content:hover & {
    transform: translateX(2em);
  }
}

@include mq('xs') {
  .cta-text__content {
    padding-right: 0;
    padding-left: 0;
  }
}

@include mq('s') {
  .cta-text__content {
    display: flex;
    justify-content: space-between;
  }

  .cta-text__text {
    width: col(5, 10);
  }

  .cta-text__appointment {
    width: col(4.5, 10);
  }

  .cta-text__arrow {
    width: col(1.5, 10);
    margin: 0;
  }
}

@include mq('xxl') {
  .cta-text__text {
    font-size: 8rem;
  }
}

@media (hover: hover) {
  .cta-text {
    &::after {
      @include get-all-space;

      content: '';
      z-index: -1;
      background: $c-mustard;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 1s $ease-80-20;
    }

    &:hover {
      .cta-text__content {
        color: $c-white;
      }

      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }

  .cta-text__text,
  .cta-text__title,
  .cta-text__arrow {
    .cta-text__content:hover & {
      color: $c-white;
    }
  }
}
